@import url(https://fonts.googleapis.com/css2?family=Amatic+SC&family=Changa:wght@200&family=Play&family=Satisfy&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Changa', sans-serif;
}

::selection {
  color: white;
  background: rgb(218, 162, 10);;
}

.App {
  background-image: url(/static/media/wooden-background.f3798710.jpg);
  background-size: contain;
  background-attachment: fixed;
}

.name {
  font-family: 'Satisfy', cursive;
}

.designation {
  font-family: 'Play', sans-serif;
}

.profile {
  width: 48%;
  padding: 80px;
  position: relative;
  border: 5px solid #fff;
  border-radius: 50%;
}

.profile-pic {
  border-radius: 47%;
  width: 102%;
  position: absolute;
  top: -29px;
  right: -3px;
}

.skill-parent {
  width: 100%;
}

.title-heading {
  font-family: 'Amatic SC', cursive;
  color: rgb(145, 86, 15);
  font-size: 48px;
  font-weight: 600;
}

.contact-info > div:first-child, .achievements-info  > div:first-child,  .certification-info  > div:first-child,
.personal-project-info > div:first-child  {
  margin-top: 15px;
}

.contact-info > div, .education > div, .achievements-info > div, .certification-info  > div,
.personal-project-info > div {
  margin-top: 10px;
}

.resume-details {
  margin-top: -60px;
}

.resume-details > div:nth-child(2n-1) {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.description {
  text-align: center;
  letter-spacing: 1px;
}

.tag {
  display: flex;
}

.tag .rectangle {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 3px 5px;
  font-weight: 600;
  border-right: 0;
  background-color: rgb(218, 162, 10);
}

.tag .right-triangle {
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-left: 18px solid rgb(218, 162, 10);
  border-bottom: 15px solid transparent;
}

.current-org, .stream {
  color: rgb(218, 162, 10);
}

.langauge-skills {
  border-radius: 15px;
  border: 3px solid rgb(218, 162, 10);
  background-color: rgb(17, 17, 17);
  padding: 8px 12px;
  float: left;
  text-align: center;
  margin-top: 4px;
}

.resume-row-3 > div:last-child {
  margin-top: -25px;
}

.resume-row-3 > div:first-child {
  margin-top: -15px;
}

.line {
  background-color: white;
  text-align: end;
  position: absolute;
}

.profile-info .line:nth-child(1) {
  height: 81%;
  top: 15%;
  right: 0;
}

.profile-info .line:nth-child(3) {
  top: 15%;
  right: 0;
}

.front-skill-image {
  z-index: 10;
}

.width-line {
  width: 2px;
}

.height-line {
  height: 2px;
}

.resume-row-1 > div:first-child > .line {
  left: 50%;
}

.resume-row-1 > div:first-child > .line:last-child {
  bottom: -70px;
}

.resume-row-1 > div:first-child > .line.width-line::before,
.profile-info .line:nth-child(3)::before,
.resume-row-1 > div:last-child.line::before,
.resume-row-2 > div:last-child > .line:nth-child(2)::after,
.resume-row-2 > div:first-child > .line:last-child::after,
.front-skill-image > .line.width-line::after {
  border: 2.5px solid white;
  border-radius: 50%;
  content: '';
  padding: 3px;
  position: absolute;
  top: -9px;
  left: -4px;
}

.profile-info .line:nth-child(3)::before {
  top: -3px;
  left: -8px;
}

.resume-row-2 > div:last-child > .line:first-child {
  top: 58%;
  left: 0;
}

.resume-row-1 > div:last-child {
  right: 0;
  padding: 0;
  top: 14.65%;
}

.resume-row-2 > div:last-child > .line:last-child {
  bottom: 12%;
  left: -20%;
}

.resume-row-2 > div:last-child > .line:nth-child(2) {
  bottom: -12.5%;
  left: 30%;
}

.resume-row-2 > div:last-child > .line:nth-child(2)::after,
.resume-row-2 > div:first-child > .line:last-child::after,
.front-skill-image > .line.width-line::after {
  bottom: -9px;
  top: auto;
}

.resume-row-2 > div:first-child > .line {
  bottom: 39%;
  right: -18%;
}

.resume-row-2 > div:first-child > .line:last-child {
  bottom: 19.1%;
  right: -18%;
}

.front-skill-image > .line {
  left: 40%;
  bottom: -15%;
}

.front-skill-image > .line.height-line {
  bottom: -5%;
  left: -10%;
}

.front-skill-image > .line:last-child {
  bottom: -15%;
  left: -10%;
  height: 10%;
}

@media only screen and (max-width: 1400px) {
  .line {
    display: none !important;
  }
}

.blink {
  opacity: 1;
  -webkit-animation: fade 1s infinite;
          animation: fade 1s infinite;
}

.h-10 {
  height: 10%;
}

.h-20 {
  height: 20%;
}

@-webkit-keyframes fade {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes fade {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.fa-map-marker-alt {
  -webkit-animation: roll 2s infinite;
          animation: roll 2s infinite;
  transition: 2s all;
}
.fa-envelope {
  -webkit-animation: roll 1.5s infinite;
          animation: roll 1.5s infinite;
  transition: 2s all;
}
.fa-mobile-alt {
  -webkit-animation: roll 2.5s infinite;
          animation: roll 2.5s infinite;
  transition: 2s all;
}

.fa-linkedin {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-animation: size 1.5s infinite;
          animation: size 1.5s infinite;
  transition: 2s all;
}

.fa-globe {
  -webkit-animation: round 2s linear infinite;
          animation: round 2s linear infinite;
}

.contact-info a:hover, .social-links a:hover {
  color: rgb(218, 162, 10) !important;
}

.achievements-info i {
  margin-top: 4px;
}

.experience-points {
  -webkit-padding-start: 20px !important;
          padding-inline-start: 20px !important;
}

@-webkit-keyframes roll {
  0% {
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg);
  }
  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  75% {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
  }
  100% {
    -webkit-transform: rotate(-340deg);
            transform: rotate(-340deg);
  }
}

@keyframes roll {
  0% {
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg);
  }
  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  75% {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
  }
  100% {
    -webkit-transform: rotate(-340deg);
            transform: rotate(-340deg);
  }
}

@-webkit-keyframes size {
  0%, 100% {
  -webkit-transform: scale(1);
          transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}

@keyframes size {
  0%, 100% {
  -webkit-transform: scale(1);
          transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}

@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media screen and (max-width: 768px) {
  .bg-dark {
    padding: 1.5rem 0 2rem !important;
  }

  .resume-details > div:first-child {
    padding-top: 1.5rem !important;
  }

  .resume-details > div:nth-child(2) {
    padding-left: 30px;
    padding-right: 30px;
  }

  .resume-details > div:nth-child(2n-1) {
    padding-left: 30px !important;
  }

  .profile-pic {
    top: -11px;
  }

  .description {
    padding-right: .5rem !important;
    padding-left: .5rem !important;
  }

  .front-skill-image {
    padding-top: 30px;
  }

  .resume-row-1 > div:nth-child(1) {
    order: 2;
  }

  .resume-row-1 > div:nth-child(2) {
    order: 1;
  }

  .resume-row-1 > div:nth-child(3) {
    order: 3;
  }
}
